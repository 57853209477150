// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// font family
$text-family: 'Heebo', sans-serif !default; //fond sizes
$font-size-base: 16px !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-medium: floor(($font-size-base * 1.15)) !default; // ~16px
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px

//@type general
$max-width: 1430px !default;
$padding-base: 15px;
$section-spacer-height: 20px;
$row-spacer-height: 20px;

/// Breakpoints map
$breakpoints: (
  'xsmall': (max-width: 400px),
  'small': (max-width: 768px),
  'medium': (max-width: 992px),
  'large': (max-width: 1200px),
  'xlarge': (max-width: 1300px),
  'xxlarge': (max-width: 1600px)
) !default;

//dir set
@if $dir != ltr and $dir != rtl {
    $dir: ltr;
}

//left / right according to dir
$left: if-ltr(right, left);
$right: if-ltr(left, right);

//Hamburger color
$hamburger-layer-color: #000;

//Header phone color
$header-phone-color: #000;

//Loaders phone color
$loaders-primary-color: #000;

//Social color schemes
$clr-facebook: #3b5998;
$clr-youtube: #cc181e;
$clr-twitter: #326ada;
$clr-instagram: #feda75;
$clr-linkedin: #0077b5;

//Main colors
$main-blue: #61e7d5;
$main-green: #00968a;
$main-text: #9b9b9b;
